
import { defineComponent, ref, onMounted, watch, computed } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import streams from "./streams";
import { IStreams } from "./streams";
import { getStreams, deleteStream, deleteManyStream } from "./streams";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { getPengguna } from "../master/pengguna/servicePengguna";
import { getMedia } from "../master/media/media";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "daftar-berita",
  components: {
    Datatable,
    ExportCustomerModal,
    AddCustomerModal,
  },
  setup() {
    const checkedCustomers = ref<Array<string>>([]);
    const tableHeader = ref([
      {
        key: "checkbox",
      },
      {
        name: "Media",
        key: "account",
        sortable: true,
      },
      {
        name: "Judul",
        key: "title",
        sortable: true,
      },
      {
        name: "Diinput Oleh",
        key: "author",
        sortable: true,
      },
      {
        name: "File Berita",
        key: "file_source",
        sortingField: "file_source",
        sortable: true,
      },
      {
        name: "Tanggal Publikasi",
        key: "date",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);
    const tableData = ref<Array<IStreams>>(streams);
    const tableKey = ref(0);
    const total_data = ref(0);
    const router = useRouter();
    const is_loading = ref(true);
    const store = useStore();
    const role = store.getters.currentUser.data.role;
    const dataUser = ref([]);
    const dataSource = ref([
      {
        label: "Media Online",
        value: "news",
      },
      {
        label: "Media Cetak",
        value: "media_cetak",
      },
      {
        label: "Media TV",
        value: "media_tv",
      },
      {
        label: "Radio",
        value: "radio",
      },
      {
        label: "Twitter",
        value: "twitter",
      },
      {
        label: "Facebook",
        value: "facebook",
      },
      {
        label: "Instagram",
        value: "instagram",
      },
      {
        label: "Youtube",
        value: "youtube",
      },
      {
        label: "Tiktok",
        value: "tiktok",
      },
      {
        label: "Forum",
        value: "forum",
      },
      {
        label: "Blog",
        value: "blog",
      },
      {
        label: "Linkedin",
        value: "linkedin",
      },
      {
        label: "Review Aplikasi",
        value: "review",
      },
    ]);
    const mediaSource = ref([]);
    const modelSelect = ref({
      account: "",
      username: "",
      fullname: "",
      source: "",
    });
    const filterTanggal = ref([]);
    const filterTanggalShortcut = ref([
      {
        text: "Hari Ini",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setHours(0, 0, 0);
          return [start, end];
        },
      },
      {
        text: "Kemarin",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setDate(start.getDate() - 1);
          start.setHours(0, 0, 0);
          end.setDate(end.getDate() - 1);
          end.setHours(23, 59, 59);
          return [start, end];
        },
      },
      {
        text: "Seminggu Lalu",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setDate(start.getDate() - 7);
          start.setHours(0, 0, 0);
          end.setHours(23, 59, 59);
          return [start, end];
        },
      },
      {
        text: "Bulan Ini",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setDate(1);
          start.setHours(0, 0, 0);
          return [start, end];
        },
      },
      {
        text: "Bulan Lalu",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setDate(1);
          start.setMonth(start.getMonth() - 1);
          start.setHours(0, 0, 0);
          end.setDate(0);
          end.setHours(23, 59, 59);
          return [start, end];
        },
      },
      {
        text: "Tahun Ini",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setMonth(0);
          start.setDate(1);
          start.setHours(0, 0, 0);
          return [start, end];
        },
      },
    ]);
    const defaultTime = ref([
      new Date(2000, 1, 1, 0, 0, 0),
      new Date(2000, 2, 1, 23, 59, 59),
    ]);
    const itemsPerPage = ref(10);
    const currentPage = ref(1);
    const search = ref<string>("");
    const is_select_all = ref(false);
    const mediaOptions = computed(() => {
      const medsos = [
        "twitter",
        "facebook",
        "instagram",
        "youtube",
        "tiktok",
        "linkedin",
        "forum",
        "blog",
        "review",
      ];
      if (modelSelect.value.source == "news") {
        return mediaSource.value.filter((el: any) => el.source == "news");
      } else if (modelSelect.value.source == "media_cetak") {
        return mediaSource.value.filter(
          (el: any) => el.source == "media_cetak"
        );
      } else if (modelSelect.value.source == "media_tv") {
        return mediaSource.value.filter((el: any) => el.source == "media_tv");
      } else if (medsos.includes(modelSelect.value.source)) {
        return [];
      } else {
        return mediaSource.value;
      }
    });

    const fetchStream = async () => {
      const payload = {
        page: currentPage.value,
        limit: itemsPerPage.value,
      };
      if (filterTanggal.value.length > 0) {
        payload["start_date"] = moment(filterTanggal.value[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        payload["end_date"] = moment(filterTanggal.value[1]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }
      if (search.value.length > 0) {
        payload["keyword"] = search.value;
      }

      if (modelSelect.value.username != "") {
        payload["author"] = modelSelect.value.username;
      }
      if (modelSelect.value.account != "") {
        payload["account"] = modelSelect.value.account;
      }
      if (modelSelect.value.source != "") {
        payload["source"] = modelSelect.value.source;
      }
      is_loading.value = true;
      const res = await getStreams(payload);
      tableData.value = res["data"];
      total_data.value = res["total"];
      is_loading.value = false;
      tableKey.value += 1;
    };
    const fetchMedia = async () => {
      const res = await getMedia("", "", 1, 10000);
      res.data.forEach((el) => {
        if (el.source == "media_tv") {
          el.nama = el.stasiun || "Stasiun Tidak Diketahui";
        }
      });
      mediaSource.value = res.data.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.nama.toLowerCase() === value.nama.toLowerCase()
          )
      );
    };
    const fetchUser = async () => {
      const res = await getPengguna();
      dataUser.value = res;
    };
    const filterTanggalChange = async (value: any) => {
      currentPage.value = 1;
      if (value) {
        fetchStream();
      }
    };
    const filterAuthorChange = async (value: any) => {
      currentPage.value = 1;
      if (value) {
        modelSelect.value.username = value;
      } else {
        modelSelect.value.username = "";
      }
      fetchStream();
    };
    const filterSourceChange = async (value: any) => {
      currentPage.value = 1;
      if (value) {
        modelSelect.value.source = value;
      } else {
        modelSelect.value.source = "";
      }
      fetchStream();
    };
    const filterMediaChange = async (value: any) => {
      currentPage.value = 1;
      if (value) {
        modelSelect.value.account = value;
      } else {
        modelSelect.value.account = "";
      }
      fetchStream();
    };
    const formatDate = (date) => {
      let tanggal = moment(date).format("YYYY-MM-DD HH:mm:ss");
      return tanggal;
    };
    const defaultDate = () => {
      const end = new Date();
      const start = new Date();
      start.setDate(start.getDate() - 7);
      start.setHours(0, 0, 0);
      end.setHours(23, 59, 59);
      let arrayDate = [] as any;
      arrayDate.push(start, end);
      filterTanggal.value = arrayDate;
    };
    const checkAll = () => {
      checkedCustomers.value.length = 0;
      if (is_select_all.value) {
        tableData.value.forEach((el: any) => {
          checkedCustomers.value.push(el._id);
        });
      } else {
        checkedCustomers.value.length = 0;
      }
    };
    const deleteSelectedNews = () => {
      Swal.fire({
        title: "Apakah anda yakin ingin menghapus berita tersebut?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Tidak",
        confirmButtonText: "Ya",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await deleteManyStream(checkedCustomers.value);
          if (res.status == 200) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              toast: true,
              title: "Berhasil! Berita berhasil dihapus",
              showConfirmButton: false,
              timer: 3000,
            });
            checkedCustomers.value.length = 0;
            fetchStream();
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              toast: true,
              title: "Gagal! Berita gagal dihapus",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }
      });
    };
    const searchItems = () => {
      if (search.value.length > 3) {
        fetchStream();
      } else if (search.value.length == 0) {
        fetchStream();
      }
    };
    const goToRoute = (id: string, name: string, detail: boolean) => {
      if (detail) {
        window.open("/berita/detail/" + id);
      } else {
        router.push({ name: name, params: { id: id } });
      }
    };
    const currentChange = (current: number) => {
      currentPage.value = current;
      fetchStream();
    };
    const itemsPerPageChange = (currentItemsPerPage: number) => {
      itemsPerPage.value = currentItemsPerPage;
      fetchStream();
    };
    const deleteBerita = (id) => {
      Swal.fire({
        title: "Apakah anda yakin ingin menghapus berita tersebut?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Tidak",
        confirmButtonText: "Ya",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await deleteStream(id);
          if (res.message == "Stream has been deleted") {
            Swal.fire({
              position: "top-end",
              icon: "success",
              toast: true,
              title: "Berhasil! Berita berhasil dihapus",
              showConfirmButton: false,
              timer: 3000,
            });
            fetchStream();
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              toast: true,
              title: "Gagal! Berita gagal dihapus",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }
      });
    };

    onMounted(() => {
      setCurrentPageTitle("Daftar Berita");
      defaultDate();
      fetchUser();
      fetchStream();
      fetchMedia();
    });

    return {
      tableData,
      tableHeader,
      search,
      searchItems,
      is_select_all,
      checkAll,
      checkedCustomers,
      deleteSelectedNews,
      fetchStream,
      fetchMedia,
      mediaOptions,
      tableKey,
      total_data,
      goToRoute,
      is_loading,
      filterTanggal,
      filterTanggalShortcut,
      filterTanggalChange,
      filterMediaChange,
      currentChange,
      itemsPerPageChange,
      itemsPerPage,
      currentPage,
      defaultTime,
      role,
      dataUser,
      dataSource,
      modelSelect,
      filterAuthorChange,
      filterSourceChange,
      formatDate,
      deleteBerita,
      mediaSource,
    };
  },
});
